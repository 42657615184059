import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { projects } from "../../components/data/projects"

import ProjectHeader from "../../components/project-header/project-header"
import { RoughNotation } from "react-rough-notation"

const theme = projects['basicsOf'].themeColor;

const ProjectPage = () => (
  <>
    <Seo title="Basics Of" />
    <ProjectHeader projectObj={projects['basicsOf']} />
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
        <p className={`primary lh`}>Basics Of is a series I am currently working on, where I build interactive 3D experiences on the web to explain complex topics. The experience would take the form of a story within a 3D environment where the user interacts and performs simple tasks. Through this, the user would cover different themes within the topic. For my first attempt, I'm building <RoughNotation type={`underline`} show multiline color={theme}><b>Basics Of: Machine Learning</b></RoughNotation>, covering the process of model selection, training, and evaluation.</p>
        <p className={`primary lh`}>Currently, Basics Of is under development and testing. The project will be going live soon. If you're interested, write to me at <a href="">thukral.abhinav@gmail.com</a> to learn more.</p>
        <div className={`center-content`}>
          <Link to='/projects'><button className={`data-hover`}>Back to Projects</button></Link>
        </div>
      </div>
    </section>
  </>
)

export default ProjectPage